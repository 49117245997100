.menu-item:hover {
  border-bottom: 3px solid #141414;
  color: white;
  text-decoration: none;
}

.menu-item {
  font-family: 'Nunito', sans-serif;
  border-bottom: 3px solid #141414;
  color: #13EBA2;
  margin: 10px;
}

.menu-item.active  {
  color: white;
  border-bottom: 3px solid white;
}


.nav-brand {
  color: #13EBA2;
  border-bottom: 3px solid #141414;
  font-family: 'Courgette', cursive;
  font-size: 1.5rem;
  margin-right: 10px;
}

.nav-brand:hover {
    border-bottom: 3px solid #141414;
    color: white;
    text-decoration: none;
}

.nav {
  margin-left: 15px;
}