.section-header{
  border-bottom: 3px solid white;

}

.page{
  width:300px;
  height:200px;
  position:absolute;
  left:50%;
  top:50%;
  margin:-100px 0 0 -150px;
}
