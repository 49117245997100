
 .EducationText {
   border: 2px solid #13EBA2;
   border-radius: 10px;
   display: inline-block;
   background-color: #263238;
   vertical-align: middle;
   -moz-transition: background 1s;
   -webkit-transition: background 1s;
   -o-transition: background 1s;
   -ms-transition: background 1s;
   transition: background 1s;
   font-family: 'Nunito', sans-serif;
 }

 .EducationText:hover{
   background-color: #515A5A;
 }

 .School {
   font-size: 30px;
 }

 .Degree {
   font-style: italic;
   line-height: 6px;
 }


 .edu-image {
   object-fit: cover;
   vertical-align: middle;
   width: 100%;
 }
