.container-fluid {
    padding-right:0;
    padding-left:0;
    margin-right:auto;
    margin-left:auto
 }

 .change-font {
    font-family: Roboto;
    color: white;
  }