html {
  background-color: #141414;
}

.homepage-bg{
  background-color: #141414;
  min-height: 100vh;
  /* background by SVGBackgrounds.com */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23121212' %3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='1'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E");

}

.about {
  color: white;
}

.font-color-black {
  color: black
}

.cards-change-fonts {
  color: black;
  font-family: Roboto;
}


.outer-card{
  margin-bottom: 50px;
  margin-right: 20px;
}


.page-headers {
    font-family: 'Nunito', sans-serif;
}

.regular-font {
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  font-size: 40px;
}

.bottom-portion {
  background-color: gray
}

a {
  text-decoration: unset !important;
}