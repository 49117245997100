.projects {
  min-width: fit-content;
  max-height: fit-content;
  padding-top: 2rem;
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 1fr;
  justify-content: center;
  grid-auto-flow: dense;
  font-family: 'Nunito', sans-serif;
}


@media screen and (min-width: 600px) {
  .projects {
    grid-template-columns: repeat(auto-fit, minmax(26rem, 32rem));
  }
}

