.p-text {
  text-align: left;
  font-size: 18px;
  font-family: 'Nunito', sans-serif;
  padding-top: 10px;
}

.page-headers {
    font-family: 'Nunito', sans-serif;
}

.header {
  color: #13EBA2;
  font-size: 24px;
  border-bottom: 2px solid #13EBA2;
}

.picture-row {
  padding-top: 10px;
  padding-bottom: 10px;

}

.p-text {
  color: #13EBA2;
}

.helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.skills-row {
  padding-top: 20px;
}


.skills-text {
  text-align: left;
  font-size: 18px;
  font-family: 'Nunito', sans-serif;
}



.language {
  display: flex;
}

.lang-text {
  display: flex;
  flex: 1 0 50%;
  align-items: center;
  justify-content: center;
  color: white
}

.tint-bg {
  padding-bottom: 40px;
}

.container-fluid {
    padding-right:0;
    padding-left:0;
    margin-right:auto;
    margin-left:auto
 }


 .ExperienceText {
   border: 2px solid #13EBA2;
   border-radius: 10px;
   display: inline-block;
   background-color: #263238;
   vertical-align: middle;
   -moz-transition: background 1s;
   -webkit-transition: background 1s;
   -o-transition: background 1s;
   -ms-transition: background 1s;
   transition: background 1s;
 }

 .ExperienceText:hover{
   background-color: #515A5A;
 }

 .Company {
   font-size: 30px;
 }

 .exp-block{
   margin-top: 15px;
 }

 .MyTitle {
   font-size: 20px;
   font-style: italic;
   line-height: 10px;
 }

 .TimeFrame {
   font-size: 20px;
   line-height: 1px;
 }

 .JobDesc {
   font-size: 16px;
 }

 .CompanySite {
   font-size: 16px;
 }
 .CompanySite a{
   color: #4FC3F7;
 }
