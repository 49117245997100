
 .ExperienceText {
   font-family: 'Nunito', sans-serif;
   border: 2px solid #13EBA2;
   border-radius: 10px;
   display: inline-block;
   background-color: #263238;
   vertical-align: middle;
   -moz-transition: background 1s;
   -webkit-transition: background 1s;
   -o-transition: background 1s;
   -ms-transition: background 1s;
   transition: background 1s;
 }

 .ExperienceText:hover{
   background-color: #515A5A;
 }

 .Company {
   font-size: 30px;
 }

 .exp-block{
   margin-top: 15px;
 }

 .MyTitle {
   font-size: 20px;
   font-style: italic;
 }

 .TimeFrame {
   font-size: 20px;
   text-align: right;
 }

 .JobDesc {
   font-size: 16px;
   white-space: pre-line;
 }

 .CompanySite {
   font-size: 16px;
 }
 .CompanySite a{
   color: #4FC3F7;
 }

 .ExpLogos {
  object-fit: cover;
  vertical-align: middle;
  width: 100%;
 }
