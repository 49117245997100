.main {
  align-items: center;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.name {
  font-family: "Courgette", cursive;
  color: #13eba2;
  font-size: 80px;
  margin-bottom: unset;
}

.social {
  font-family: "Nunito", sans-serif;
  color: #13eba2;
  margin-right: 15px;
  margin-left: 15px;
  display: inline-block;
}

.social:hover {
  color: white;
}

.jobTitle {
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  font-size: 40px;
  color: #13eba2;
  margin-bottom: unset;
}

.fill {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fill img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.centerNav {
  text-align: center;
  border-right: 5px solid #13EBA2;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 412px;
}

.menuItem {
  font-family: 'Nunito', sans-serif;
  border-bottom: 3px solid transparent;
  color: #13EBA2;
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
}

.menuItem:hover {
  border-bottom: 3px solid white;
  color: white;
  text-decoration: none;
}


.rowLinks {
  margin-top: 40px;
}

.pageLinks {
  font-weight: 500;
  margin-top: 40px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
