.card {
  margin-bottom: 1rem;
  border: 2px solid #212020;
  background-color: #141414;
}

.cardUpper, .fullWidthCard {
  display: flex;
  min-width: 26rem;
  box-sizing: content-box;
  border-bottom: 2px solid #212020;
}

.fullWidthCard {
  grid-column: 1 / -1;
  margin-bottom: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
}

.badge {
  background-color: #13eba2;
  color: #141414 !important;
}

.type {
  display: flex;
  flex-direction: row;
  height: 20.4px;
}

.links {
  margin-left: auto;
}

.links > * {
  margin: 5px;
  color: #13eba2;
}

.firstCol {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.secondCol {
  flex: 1;
  text-align: center;
}

.cardUpper {
  height: 11rem;
}

.cardLower {
  text-align: center;
}

.firstCol > * {
  margin-bottom: 0.5rem;
}

.card:hover {
  background-color: #212020;
}

.card.active {
  margin-bottom: unset;
}

.card.active:hover {
  margin-bottom: unset;
}

.active {
  background-color: #212020;
}

/* .card:hover, .fullWidthCard:hover{
    box-shadow: 2px 2px 2px 2px gray;
} */



.body {
  flex: 1;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  }

.body > * {
  margin-bottom: 0.5rem;
}


.title {
  line-height: 1.4rem;
  margin-bottom: 0.5rem;
  text-align: left;
  font-size: 18px;
}

.textShort {
  font-size: 14px;
}

.picture {
  flex: 1;
  width: 12rem;
  height: 11rem;
  object-fit: contain;
  padding: 5px;
  border-right: 2px solid #212020;
}


.video {
  max-width: 200px;
  width: 100%;
  align-self: center;
}

.year, .for {
  font-size: 12px;
}

.tech {
  display: flex;
  flex-direction: row;
}

.tech > * {
  margin-right: 5px;
  background-color: #13eba2;
  color: #141414 !important;
}

.topLeft {
  position: absolute;
  top: 8px;
  left: 16px;
}

.container {
  position: relative;
  text-align: center;
  color: white;
}

